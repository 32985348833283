
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator';
@Component
class ModalPopup extends Vue {
  @Prop() private showModalPopup?: boolean;
  @Prop() private HeaderContent?: string;
  @Prop() private bodyHeading?: string;
  @Prop() private bodyContent?: string;
  @Prop() private showCancelButton?: boolean;
  @Prop() private cancelButtonText?: string;
  @Prop() private confirmButtonText?: string;
  @Prop() private showCloseButton?: boolean;
  @Prop() private messageIconType?: boolean;

  private localShowModalPopup?: boolean = false;
  private localshowCancelButton?: boolean = true;
  private localcancelButtonText?: string = '';
  private localConfirmButtonText?: string = '';

  private mounted() {
    this.localShowModalPopup = this.showModalPopup;
    this.localshowCancelButton = this.showCancelButton !== undefined ? this.showCancelButton : true;

    this.localcancelButtonText = (this.cancelButtonText !== undefined && this.cancelButtonText !== '') ? this.cancelButtonText : this.$t('CancelButton').toString();

    this.localConfirmButtonText = (this.confirmButtonText !== undefined && this.confirmButtonText !== '') ? this.confirmButtonText : this.$t('YesButton').toString();
  }
  private Cancel() {
    this.$emit('confirm-click', false);
  }
  private clickYes() {
    this.$emit('confirm-click', true);
  }
  private getFontAwasomeIcon(messageType: string) {
    if (messageType === 'error') {
      return 'fa fa-times-circle-o message-icon';
    }
  }
}
export default toNative(ModalPopup);
