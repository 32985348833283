
import videojs from '@mux/videojs-kit';
import '@mux/videojs-kit/dist/index.css';
import { VodVideoDetail } from '@/entities/VodDetail';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator';
import { Store } from '@/main';
@Component({
  components: {
  },
})
class VideoPlayer extends Vue {
  @Prop() private vodVideoDetail?: VodVideoDetail;
  @Prop() private entityTitle?: string;
  // private vodVideoDetail = {
  //   Id: 6843,
  //   VodDetailId: 7102,
  //   AssetId: 'Jx01Je5ueO02H4Fb01ndaOmzlCU86801ilzOANqpCn6GhU8',
  //   PlayBackUrl: 'DdhQ02KAqbEatOKgaoOk90102J02meuHh00btjaHTAoXooTY.m3u8?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJEZGhRMDJLQXFiRWF0T0tnYW9PazkwMTAySjAybWV1SGgwMGJ0amFIVEFvWG9vVFkiLCJraWQiOiJ4eERxdGUwMWIwMlUwMHhUWDAyT2ZLbTJqcjN5aFhoTVJnVXBzMjJZYVpaOFVFNCIsImV4cCI6MTcxNDQ1NjgxOSwiY3VzdG9tIjp7ImFwcElkIjoyNTk4LCJldmVudElkIjoxMjk2Njl9LCJhdWQiOiJ2In0.iZUZyuqqaeq095yDmD6wDzmPASLxs4bnAcjWYpoj9sNSP_aBx5fmYeytGVz4N6rhXNVlrakjbTYgV4XfjmNESgOohCWLKf3Onk-283Pm4Chv5AbyhsgqUxfXbjuR06-wRuMFnExrWRyQFP2M-TFHYnCRI21QLMxwyFdeRRjPBdCtvEFxT3WH82Cj1cbCTeGKbn20mUTZm-irgi6Lbb8jZIsdVjEy2UKN1H9iL8HRuv4-_2DBWq3qBNjA88NLEqUZPsIxnpeF0H6n8DEJzrPQ9aIxGFCBM5gl4yDOHAokr3WQUhMyD7aLv15qD52rM3eZmBDNd-pHzn-hDi9tq8Wt2A',
  //   Duration: 259.792867,
  //   AspectRatio: '16:9',
  //   FrameRate: 29.97,
  //   Resolution: 'SD',
  //   Width: 640,
  //   Height: 360,
  //   IsDeleted: false,
  //   LiveStreamPlayBackUrl: '',
  //   PosterUrl: 'https://image.mux.com/18hTgsLemuXNUIJlqoe5EWwaVdJyRZYi9mB4KQTNVpQ/thumbnail.jpg?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOGhUZ3NMZW11WE5VSUpscW9lNUVXd2FWZEp5UlpZaTltQjRLUVROVnBRIiwia2lkIjoieHhEcXRlMDFiMDJVMDB4VFgwMk9mS20yanIzeWhYaE1SZ1VwczIyWWFaWjhVRTQiLCJleHAiOjE3MTI4MjI5NTUsImN1c3RvbSI6eyJhcHBJZCI6MjU5OCwiZXZlbnRJZCI6MTE5MjExfSwiYXVkIjoidCJ9.rSyk-bkWIbwtyRn2tIhvfz42_SFEj7pPqINSfEe8-DdC_Xasnl0GIuhdjAcOoZOfcnmYIvaF9Wcx5EW6pDz-1E-VUCQBjDC_kM_ZEnYMUnTeuPVkCq59H1FlGOdSAsFR4DSUCKUsUqFGpvkUMbX3Lccvthf6Kut0UJOguBThBNJ-F8ThuhMumV1VKUyBYvvI0wb1g9z3eKMU9gCBine7FuT5_sC93ufvuvRCeHQq8a0RlvOCF3diZ6Usg9TXylvFuM_sc4lVOmdoRjdj7M6ZLA71Dv94eYEeDTqvr01huxuCpRi-4wb2JcTiH47uuhakTojfMXjwb9_et1UcMKr9bw',
  //   VideoEncryptId: 'FEC4tg1DBFukLFMFMsg1XhEK1YnhWKZud6yiaBeTmaZlGpNMlYSZhd6FXvMa+Y7Fz2KRvoYjmy8=',
  // };
  // private entityTitle = 'Program';
  private mounted() {
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    const userHash = reqInfo.UserHash;
    const muxEnvironmentKey = reqInfo.MuxEnvironmentKey;
    const player = videojs('my-player', {
      responsive: true,
      controlBar: {
        pictureInPictureToggle: false,
      },
    });
    player.mux({
      debug: false,
      data: {
        env_key: muxEnvironmentKey,
        video_id: this.vodVideoDetail!.VideoEncryptId,
        player_name: 'Admin',
        player_init_time: Date.now(),
        video_title: this.entityTitle,
        viewer_user_id: userHash,
      },
    });
  }
  private Cancel() {
    videojs.getPlayer('my-player').dispose();
    this.$emit('close-video-player', false);
  }
}
export default toNative(VideoPlayer);
