
import { Component, toNative, Vue } from 'vue-facing-decorator';
import WatchLiveEditPane from '@/components/WatchLive/WatchLiveEditPane.vue';

@Component({
  components: {
    WatchLiveEditPane,
  },
})
class WatchLive extends Vue {
}
export default toNative(WatchLive);
