import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-164114b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-margin-in-fields" }
const _hoisted_2 = { class: "inline-flex100 align-items-center" }
const _hoisted_3 = { class: "main-title-width" }
const _hoisted_4 = { class: "main-heading primary-heading" }
const _hoisted_5 = {
  id: "pills-tab",
  class: "nav nav-pills center-pills top-margin-in-fields",
  role: "tablist"
}
const _hoisted_6 = {
  id: "pills-tabContent",
  class: "tab-content"
}
const _hoisted_7 = {
  id: "pills-detailed",
  class: "tab-pane fade show active",
  role: "tabpanel",
  "aria-labelledby": "pills-detailed-tab",
  style: {"opacity":"1"}
}
const _hoisted_8 = {
  id: "pills-consolidated",
  class: "tab-pane fade",
  role: "tabpanel",
  "aria-labelledby": "pills-consolidated-tab",
  style: {"opacity":"1"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VirtualMeetingSessionAnalytics = _resolveComponent("VirtualMeetingSessionAnalytics")!
  const _component_PreRecordedMeetingHighcharts = _resolveComponent("PreRecordedMeetingHighcharts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "btn btn-secondary btn-size-default",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Cancel()))
        }, _toDisplayString(_ctx.$t("Button.Back")), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('VirtualMeetings.HighChart.Title')), 1)
      ])
    ]),
    _createElementVNode("ul", _hoisted_5, [
      _createElementVNode("li", {
        class: _normalizeClass(["nav-item", { 'navaigation-pills-width': !_ctx.isMobileView, 'navaigation-pills-width-mobile': _ctx.isMobileView}])
      }, [
        _createElementVNode("a", {
          id: "pills-detailed-tab",
          class: "nav-link active detailed-pill btn-size-default",
          "data-toggle": "pill",
          href: "#pills-detailed",
          role: "tab",
          "aria-controls": "pills-detailed",
          "aria-selected": "true",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDetailedSection()))
        }, _toDisplayString(_ctx.$t("VirtualMeetings.Detailed")), 1)
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass(["nav-item", { 'navaigation-pills-width': !_ctx.isMobileView, 'navaigation-pills-width-mobile': _ctx.isMobileView}])
      }, [
        _createElementVNode("a", {
          id: "pills-consolidated-tab",
          class: "nav-link consolidated-pill btn-size-default",
          "data-toggle": "pill",
          href: "#pills-consolidated",
          role: "tab",
          "aria-controls": "pills-consolidated",
          "aria-selected": "false",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showConsolidatedSection()))
        }, _toDisplayString(_ctx.$t("VirtualMeetings.Consolidated")), 1)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_VirtualMeetingSessionAnalytics, {
          "vonage-analytics-for-graph": _ctx.vonageAnalyticsForGraph,
          "selected-program-id": _ctx.selectedProgramId,
          "module-type": _ctx.moduleType
        }, null, 8, ["vonage-analytics-for-graph", "selected-program-id", "module-type"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_PreRecordedMeetingHighcharts, {
          "video-detail": _ctx.videoDetail,
          "video-on-demand-metrics": _ctx.videoOnDemandMetrics,
          "stream-type": 3
        }, null, 8, ["video-detail", "video-on-demand-metrics"])
      ])
    ])
  ]))
}