
import { Component, Vue, Prop, toNative } from 'vue-facing-decorator';

import { VideoOnDemandMetrics, VodVideoMetricsRequest } from '@/entities/VodDetail';
import VodTypeEnums from '@/enums/VodTypeEnums';
import VodModuleTypeEnum from '@/enums/VodModuleTypeEnum';
import { Root } from '@/main';
import { CommonHighchartData } from '@/entities/Notification/CommonHighchartData';
import HighchartPieChart from '@/components/Common/HighchartPieChart.vue';

@Component({
  components: {
    HighchartPieChart,
  },
})
class PreRecordedMeetingHighcharts extends Vue {
  @Prop() private videoDetail?: VodVideoMetricsRequest;
  @Prop() private videoOnDemandMetrics?: VideoOnDemandMetrics;
  @Prop() private streamType?: number;
  private isMobileView = false;
  private optionNames: any[] =  [];
  private UniqueViewers: number[] = [];
  private WatchViewers: number[] = [];
  private More = require('highcharts/highcharts-more');
  private Exporting = require('highcharts/modules/exporting');
  private ExportingData = require('highcharts/modules/export-data');
  private ChartAnnotations = require('highcharts/modules/annotations');
  private localVodVideoAnalytics: VideoOnDemandMetrics = VideoOnDemandMetrics.createEmpty();
  private Title = '';
  private showCancelButton = false;
  private watchedUsersChartData: CommonHighchartData = CommonHighchartData.createEmpty();
  private uniqueViewersChartData: CommonHighchartData = CommonHighchartData.createEmpty();
  private mounted() {
    Root.on('show-detailed-section', (show: boolean) => {
      this.showCancelButton = show;
    });
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.localVodVideoAnalytics = this.videoOnDemandMetrics!;

    if (this.streamType === VodTypeEnums.RTMP && this.localVodVideoAnalytics !== undefined && this.localVodVideoAnalytics !== null) {
      this.Title = this.$t('VirtualMeetings.RtmpRecorded.Analytics.Title').toString();
      this.GetUniqueViewersData();
      this.GetWatchedUsersData();
    } else {
      this.Title = this.$t('VirtualMeetings.PreRecorded.Analytics.Title').toString();
      this.GetVodUniqueViewersData(this.streamType);
      this.GetVodWatchedUsersData(this.streamType);
    }
  }
  private GetUniqueViewersData() {
    this.optionNames = [];
    this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountUniqueViewersDuringSession').toString());
    if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountAdditionalViewersInEvent').toString());
    }
    this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountViewersBeforeAndAfterEvent').toString());
    this.UniqueViewers.push(this.localVodVideoAnalytics.CountUniqueViewersDuringSession);
    if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
      this.UniqueViewers.push(this.localVodVideoAnalytics.CountAdditionalViewersInEvent);
    }
    this.UniqueViewers.push(this.localVodVideoAnalytics.CountViewersBeforeAndAfterEvent);
    const chartData: any[] = [];
    this.UniqueViewers.forEach((element)=> {
      chartData.push({percentage: element,votes:element});
    });
    const legendsFontSize = this.isMobileView ? '' : 'font-size-16';
    const legendsTitle = '<div class="'+ legendsFontSize +' font-weight-normal"><span><b>A total of ' + this.localVodVideoAnalytics.TotalPeopleWatched + ' </b> viewers <b>watched</b> the video <b>' + this.localVodVideoAnalytics.TotalTimesWatched  + ' times</b></span></div>';
    this.uniqueViewersChartData = CommonHighchartData.SetBarChartData(this.optionNames as string[], chartData, 'pie', legendsTitle);
  }
  private GetWatchedUsersData() {
    this.optionNames = [];
    this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountUniqueViewersDuringSessionMinutes').toString());
    if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountAdditionalViewersInEventMinutes').toString());
    }
    this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountViewersBeforeAndAfterEventMinutes').toString());
    this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedDuringSession);
    if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
      this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedDuringEvent);
    }
    this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedBeforeAndAfterEvent);
    const chartData: any[] = [];
    this.WatchViewers.forEach((element)=> {
      chartData.push({percentage: element,votes:element});
    });
    const legendsFontSize = this.isMobileView ? '' : 'font-size-16';
    const legendsTitle = '<div class="'+ legendsFontSize +' font-weight-normal"><span>The session has been watched for<b> a total of ' + this.localVodVideoAnalytics.TotalTimeSpanWatched.toFixed(2) + ' minutes</b>. The average viewing time per viewer is <b>' + this.localVodVideoAnalytics.AverageTimePerUserWatched.toFixed(2) + ' mins </b></span></div>';
    this.watchedUsersChartData = CommonHighchartData.SetBarChartData(this.optionNames as string[], chartData,'pie', legendsTitle);
  }
  private GetVodUniqueViewersData(type: number|undefined) {
    this.optionNames = [];
    if (type === VodTypeEnums.MeetAppLiveRecording) {
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountUniqueViewersDuringSession').toString());
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountAdditionalViewersInEvent').toString());
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountViewersBeforeAndAfterEvent').toString());
    } else {
      if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
        this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountUniqueViewersDuringSession').toString());
        this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountAdditionalViewersInEvent').toString());
      } else {
        this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountUniqueViewersDuringSessionInformation').toString());
      }
      this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountViewersBeforeAndAfterEvent').toString());
    }
    this.UniqueViewers.push(this.localVodVideoAnalytics.CountUniqueViewersDuringSession);
    if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
      this.UniqueViewers.push(this.localVodVideoAnalytics.CountAdditionalViewersInEvent);
    }
    this.UniqueViewers.push(this.localVodVideoAnalytics.CountViewersBeforeAndAfterEvent);
    const chartData: any[] = [];
    this.UniqueViewers.forEach((element)=> {
      chartData.push({percentage: element,votes:element});
    });
    const legendsFontSize = this.isMobileView ? '' : 'font-size-16';
    const legendsTitle = '<div class="'+ legendsFontSize +' font-weight-normal"><span><b>A total of ' + this.localVodVideoAnalytics.TotalPeopleWatched + ' </b> viewers <b>watched</b> the video <b>' + this.localVodVideoAnalytics.TotalTimesWatched  + ' times</b></span></div>';
    this.uniqueViewersChartData = CommonHighchartData.SetBarChartData(this.optionNames as string[], chartData, 'pie', legendsTitle);
  }
  private GetVodWatchedUsersData(type: number|undefined) {
    this.optionNames = [];

    if (type === VodTypeEnums.MeetAppLiveRecording) {
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountUniqueViewersDuringSessionMinutes').toString());
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountAdditionalViewersInEventMinutes').toString());
      this.optionNames.push(this.$t('VirtualMeetings.RtmpAnalytics.CountViewersBeforeAndAfterEventMinutes').toString());
    } else {
      if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
        this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountUniqueViewersDuringSessionMinutes').toString());
        this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountAdditionalViewersInEventMinutes').toString());
      } else {
        this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountUniqueViewersDuringSessionMinutesInformation').toString());
      }
      this.optionNames.push(this.$t('VirtualMeetings.Analytics.CountViewersBeforeAndAfterEventMinutes').toString());
    }
    this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedDuringSession);
    if (this.videoDetail!.EntityType !== VodModuleTypeEnum[1].toString()) {
      this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedDuringEvent);
    }
    this.WatchViewers.push(this.localVodVideoAnalytics.TimeSpanWatchedBeforeAndAfterEvent);
    const chartData: any[] = [];
    this.WatchViewers.forEach((element)=> {
      chartData.push({percentage: element,votes:element});
    });
    const legendsFontSize = this.isMobileView ? '' : 'font-size-16';
    const legendsTitle = '<div class="'+ legendsFontSize +' font-weight-normal"><span>The video has been played for<b> a total of ' + this.localVodVideoAnalytics.TotalTimeSpanWatched.toFixed(2) + ' minutes</b> The average viewing time per viewer is <b>' + this.localVodVideoAnalytics.AverageTimePerUserWatched + ' mins </b></span></div>';
    this.watchedUsersChartData = CommonHighchartData.SetBarChartData(this.optionNames as string[], chartData,'pie', legendsTitle);
  }
  private Cancel() {
    Root.emit('show-pre-recorded-video-analytics-section', false);
    Root.emit('program-scroll-to-element', 'programVirtualMeetingDiv');
  }
}
export default toNative(PreRecordedMeetingHighcharts);
