
import { Component, Vue, Prop, Watch, toNative } from 'vue-facing-decorator';
import { CommonHighchartData } from '@/entities/Notification/CommonHighchartData';
import { Chart } from 'highcharts-vue';
import HighchartHelper from '@/helper/HighchartHelper';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
@Component({
  components: {
    highcharts: Chart,
  },
})
class HighchartPieChart extends Vue {
  @Prop() chartData!: CommonHighchartData;
  @Prop() showPercentage!: boolean;
  private localChartData: CommonHighchartData = CommonHighchartData.createEmpty();
  private showCharts = false;
  private maxCharacters = 0;
  private highchartData: object = {};
  private screenWidth = 0;
  private pieHeight = 0;
  private mounted() {
    this.localChartData = Object.assign({},this.chartData);
    this.screenWidth = window.innerWidth;
    this.GetPieChartHeight(this.localChartData.ChartCategories);
    this.SetChartData();
    if(Object.keys(this.highchartData).length > 0) {
      this.showCharts = true;
    }
  }
  private GetPieChartHeight(ChartCategories: string[]) {
    ChartCategories.forEach((e)=> {
      this.maxCharacters = this.maxCharacters > e.length ? this.maxCharacters : e.length;
    });
    if(this.screenWidth < 1200) {
      this.pieHeight = 400;
    } else {
      this.pieHeight = ChartCategories.length <= 5 ? 400 : 500;
    }
    this.pieHeight += ChartCategories.length * 15;
  }
  private SetChartData() {
    exporting(Highcharts);
    const totalcount = this.localChartData.totalCount;
    const showPercentage = this.showPercentage !== null && this.showPercentage !== null ? this.showPercentage : true;
    this.highchartData = {
      chart: {
        type: HighchartHelper.PieChart,
        backgroundColor: HighchartHelper.ChartBackgroundColor,
        height: this.pieHeight,
        marginRight: HighchartHelper.ChartMargin,
        marginLeft: HighchartHelper.ChartMargin,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: HighchartHelper.AreCreditsEnabled,
      },
      colors: this.localChartData.Colors,
      exporting: HighchartHelper.Exporting,
      tooltip: {
        outside: true,
        animation: false,
        useHTML: true,
        style: {
          fontFamily: HighchartHelper.FontFamily,
          fontWeight: this.localChartData.FontWeight,
        },
        backgroundColor: HighchartHelper.tooltipBackgroundColor,
        formatter(this: Highcharts.PointLabelObject) {
          return this.key + ' = ' + (showPercentage === false ? this.y! : Math.round(this.y! * totalcount /100));
        },
      },
      legend: {
        enabled: true,
        verticalAlign: 'bottom',
        layout: 'horizontal',
        title: {
          text: this.localChartData.legendsTitle,
        },
        itemMarginTop: HighchartHelper.LegendMargin,
        itemMarginBottom: HighchartHelper.LegendMargin,
        itemStyle: {
          fontWeight: this.localChartData.FontWeight,
          fontFamily: this.localChartData.FontFamily,
          fontSize: this.localChartData.FontSize,
          color: this.localChartData.FontColor,
        },
      },
      series: [
        {
          point: {
            events: {
              legendItemClick() {
                return false;
              },
            },
          },
          showInLegend: true,
          data: this.localChartData.pieChartData,
          dataLabels: {
            style: {
              fontWeight: this.localChartData.FontWeight,
              fontFamily: this.localChartData.FontFamily,
              fontSize: this.localChartData.FontSize,
              color: this.localChartData.FontColor,
            },
            useHTML: true,
            formatter(this: Highcharts.PointLabelObject) {
              return this.point.y === 0 ? null : '<span class="data-label" title = "' + this.point.name + '">' + this.point.y + (showPercentage ? '%' : '')  + '</span>';
            },
          },
        },
      ],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            series: {
              dataLabels: {
                distance: HighchartHelper.TickDistance,
              },
            },
          },
        }],
      },
    };
  }
  @Watch('chartData')
  private CheckChangeInChartData(newVal: CommonHighchartData) {
    this.localChartData = newVal;
    this.GetPieChartHeight(this.localChartData.ChartCategories);
    this.SetChartData();
  }
}
export default toNative(HighchartPieChart);
