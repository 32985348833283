
const VirtualMeetingTypeEnum = {
  MuxString: 'Mux',
  RTMPString: 'RTMP',
  HLSString: 'HLS',
  VonageString: 'Vonage',
  VonageMUX: 'VonageMUX',
  Others: 'Others',
};

export default Object.freeze(VirtualMeetingTypeEnum);
