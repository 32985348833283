export class Language {
  public static createEmpty(): Language {
    return new Language();
  }
  constructor(
    public Id: number = 0,
    public LanguageName: string = '',
    public LanguageCode: string = '',
  ) { }
}
export class LanguageArray {
  public arrSupportedLanguages: Language[] = [];
  constructor() {
    // Do nothing
  }
}
export class VirtualMeetingCaptionLanguage extends LanguageArray {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(rootObj: any, $store: any, $t: any) {
    super();
    this.arrSupportedLanguages.push({ Id: 0, LanguageName: 'Select Language', LanguageCode: ''});
    this.arrSupportedLanguages.push({ Id: 5, LanguageName: 'Danish', LanguageCode: 'da'});
    this.arrSupportedLanguages.push({ Id: 1, LanguageName: 'English', LanguageCode: 'en'});
    this.arrSupportedLanguages.push({ Id: 3, LanguageName: 'Finnish', LanguageCode: 'fi'});
    this.arrSupportedLanguages.push({ Id: 2, LanguageName: 'French', LanguageCode: 'fr'});
    this.arrSupportedLanguages.push({ Id: 6, LanguageName: 'German', LanguageCode: 'de'});
    this.arrSupportedLanguages.push({ Id: 7, LanguageName: 'Norwegian', LanguageCode: 'no'});
    this.arrSupportedLanguages.push({ Id: 8, LanguageName: 'Spanish', LanguageCode: 'es'});
    this.arrSupportedLanguages.push({ Id: 4, LanguageName: 'Swedish', LanguageCode: 'sv'});
  }
}
