export class VodDetail {
  public static createEmpty(): VodDetail {
    return new VodDetail();
  }
  constructor(
    public Id: number = 0,
    public VodType: string = '',
    public IsDeleted: boolean = false,
    public CreatedAt: Date = new Date(),
    public CreatedBy: string = '',
    public UpdatedAt: Date = new Date(),
    public UpdatedBy: string = '',
    public VODLatestStatus: string = '',
    public VodStreamingType: number = 0,
    public LiveStreamKey: string = '',
    public IsRecorded: boolean = false,
    public ErrorDetail: string = '',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public VodVideoDetail?: VodVideoDetail|null,
    public VodVideoTextDetails: VodVideoTextDetail[] = [],
    public VodVideoAnalytics?: VideoOnDemandMetrics|null,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public VodDetailApplicationInstance?: VodDetailApplicationInstance|null,
  ) {
  }
}


export class VodDetailApplicationInstance {
  public static createEmpty(): VodDetailApplicationInstance {
    return new VodDetailApplicationInstance();
  }
  constructor(
    public Id: number = 0,
    public VodDetailId: number = 0,
    public EntityId: number = 0,
    public ApplicationId: number = 0,
    public EventId: number = 0,
    public EntityName: string = '',
    public IsPublished: boolean = false,
    public PublishDate?: Date,
    public PublishOption: number = 2,
    public PublishUTCDate?: Date,
    public StorageTillDate?: Date,
    public StorageTillUTCDate?: Date,
    public IsDeleted: boolean = false,
    public UploadedOn: Date = new Date(),
    public UploadedBy: string = '',
    public  IsSharedVodVideo = false,
  ) { }
}

export class VodVideoDetail {
  public static createEmpty(): VodVideoDetail {
    return new VodVideoDetail();
  }
  constructor(
    public Id: number = 0,
    public VodDetailId: number = 0,
    public AssetId: number = 0,
    public PlayBackUrl: string = '',
    public PlayBackId: string = '',
    public PosterUrl: string = '',
    public Duration: string = '',
    public Resolution: string = '',
    public FrameRate: string = '',
    public AspectRatio: string = '',
    public Width: string = '',
    public Height: string = '',
    public VideoEncryptId: string = '',
  ) { }
}
export class VodEventDetails {
  public static createEmpty(): VodEventDetails {
    return new VodEventDetails();
  }
  constructor(
    public Id: number = 0,
    public VodDetailId: number = 0,
    public VodEventId: string = '',
    public VodEventType: string = '',
    public CreatedAt: Date = new Date(),
  ) {
  }
}
export class VodVideoTextDetail {
  public static createEmpty(): VodVideoTextDetail {
    return new VodVideoTextDetail();
  }
  constructor(
    public Id: number = 0,
    public VodDetailId: number = 0,
    public LanguageName: string = '',
    public LanguageCode: string = '',
    public VodTrackId: string = '',
    public IsDeleted: boolean = false,
  ) {
  }
}
export class VodSignalRStatus {
  public static createEmpty(): VodSignalRStatus {
    return new VodSignalRStatus();
  }
  constructor(
    public Id: number = 0,
    public VodException: string  = '',
    public VodStatus: string = '',
    public VodVideo?: VodVideoDetail|null,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public VodVideoTextDetail?: VodVideoTextDetail|null,
  ) {
  }
}
export class VideoOnDemandMetrics {
  public static createEmpty(): VideoOnDemandMetrics {
    return new VideoOnDemandMetrics();
  }
  constructor(
    public Id: number = 0,
    public VodDetailId: number  = 0,
    public EntityId: number = 0,
    public TotalPeopleWatched: number = 0,
    public TotalTimesWatched: number = 0,
    public TotalTimeSpanWatched: number = 0,
    public AverageTimePerUserWatched: number = 0,
    public CountUniqueViewersDuringSession: number = 0,
    public CountAdditionalViewersInEvent: number = 0,
    public CountViewersBeforeAndAfterEvent: number = 0,
    public TimeSpanWatchedDuringSession: number = 0,
    public TimeSpanWatchedDuringEvent: number = 0,
    public TimeSpanWatchedBeforeAndAfterEvent: number = 0,
    public TotalTimesLiveWatched: number = 0,
    public TotalTimesRecordingWatched: number = 0,
  ) {
  }
}
export class VodVideoMetricsRequest {
  public static createEmpty(): VodVideoMetricsRequest {
    return new VodVideoMetricsRequest();
  }
  constructor(
    public VideoId: number = 0,
    public VideoDetailId: number = 0,
    public ApplicationId: number = 0,
    public ApplicationInstanceId: number = 0,
    public EntityId: number = 0,
    public EntityType: string = '',
  ) {
  }
}
