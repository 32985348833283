
export class ProductPrice {
  public static createEmpty(): ProductPrice {
    return new ProductPrice();
  }
  constructor(
    public Id: number = 0,
    public ProductId: number = 0,
    public CurrencyId: number = 0,
    public ProductPriceAmount: number = 0,
    public UnitValue: number = 0,
    public UnitText: string = '',
  ) { }
}
