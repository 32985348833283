
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { VonageSessionData } from '@/entities/VonageSessionData';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import VodTypeEnums from '@/enums/VodTypeEnums';
import VirtualMeetingEnums from '@/enums/VirtualMeetingEnums';
import { Root } from '@/main';
import LiveRecordedVideoAnalytics from '@/entities/Emits/WatchLive/LiveRecordedVideoAnalytics';
@Component
class MeetAppLiveConsolidatedDetails extends Vue {
  @Prop() private meetAppLiveAnalyticsdata?: VonageSessionData;
  @Prop() private selectedProgramsDetails?: ProgramItem;
  private vonageAnalyticslocal: VonageSessionData = VonageSessionData.createEmpty();
  private showSessionAnalyticsdata = true;
  private isRecorded = true;
  private recordedOnMessage = '';
  private uniqueViewersDuringSession = 0;
  private mounted() {
    this.vonageAnalyticslocal = this.meetAppLiveAnalyticsdata!;
    if (this.selectedProgramsDetails!.VirtualMeetingType === VirtualMeetingEnums.VonageMUX) {
      if (this.vonageAnalyticslocal !== null && this.vonageAnalyticslocal !== undefined) {
        this.uniqueViewersDuringSession = this.vonageAnalyticslocal.Attendees;
      }
    } else if (this.selectedProgramsDetails !== null &&  this.selectedProgramsDetails !== undefined && this.selectedProgramsDetails.VodDetail !== undefined && this.selectedProgramsDetails.VodDetail !== null && this.selectedProgramsDetails.VodDetail.VodVideoAnalytics !== undefined &&  this.selectedProgramsDetails.VodDetail.VodVideoAnalytics !== null && this.selectedProgramsDetails.VodDetail.VodVideoAnalytics.CountUniqueViewersDuringSession !== undefined && this.selectedProgramsDetails.VodDetail.VodVideoAnalytics.CountUniqueViewersDuringSession !== null) {

      this.uniqueViewersDuringSession = this.selectedProgramsDetails.VodDetail.VodVideoAnalytics.CountUniqueViewersDuringSession!;
    }
    this.ShowRecordingMessage();
  }
  private updated() {
    if (this.selectedProgramsDetails!.VirtualMeetingType === VirtualMeetingEnums.VonageMUX) {
      if (this.vonageAnalyticslocal !== null && this.vonageAnalyticslocal !== undefined) {
        this.uniqueViewersDuringSession = this.vonageAnalyticslocal.Attendees;
      }
    }
    this.ShowRecordingMessage();
  }
  private ShowRecordingMessage() {
    if (this.selectedProgramsDetails!.VodDetail !== null && this.selectedProgramsDetails!.VodDetail !== undefined) {

      if (this.selectedProgramsDetails!.VodDetail.VODLatestStatus !== 'VideoLiveStreamActive' && !this.selectedProgramsDetails!.VodDetail.IsRecorded) {
        this.recordedOnMessage = this.$t('VirtualMeetings.RecordedOnMessage').toString();
        this.isRecorded = false;

      } else if (this.selectedProgramsDetails!.VodDetail.VODLatestStatus === 'VideoLiveStreamActive' && this.selectedProgramsDetails!.VodDetail.IsRecorded) {
        this.recordedOnMessage = this.$t('VirtualMeetings.RecordingInProgress').toString();
        this.isRecorded = false;
      } else {
        this.isRecorded = true;
      }
    }
  }
  private ShowMeetAppLiveAnalytics() {
    if (this.selectedProgramsDetails!.VirtualMeetingType === 'Vonage') {
      return true;
    }
    return false;
  }
  private loadGraphComprehensiveDetails() {
    if (this.vonageAnalyticslocal == null) {
      this.vonageAnalyticslocal = this.meetAppLiveAnalyticsdata!;
    }
    const liveRecordedAnalytics: LiveRecordedVideoAnalytics = LiveRecordedVideoAnalytics.createEmpty();
    liveRecordedAnalytics.ShowChart = true;
    liveRecordedAnalytics.VonageAnalytics = this.vonageAnalyticslocal;
    if (this.selectedProgramsDetails!.VodDetail !== null && this.selectedProgramsDetails!.VodDetail !== undefined) {
      if (this.selectedProgramsDetails!.VodDetail.VodStreamingType === VodTypeEnums.RTMP) {
        Root.emit('show-pre-recorded-video-analytics-section', true);
      } else if (this.selectedProgramsDetails!.VirtualMeetingType === 'VonageMUX') {
        // Root.emit('show-pre-recorded-video-analytics-section', true);
        // Root.emit('show-graph-comprehensive-data', true, this.vonageAnalyticslocal);
        Root.emit('show-meetapp-live-recorded-video-analytics-section', liveRecordedAnalytics);
      } else {
        Root.emit('show-graph-comprehensive-data', liveRecordedAnalytics);
      }
    } else {
      Root.emit('show-graph-comprehensive-data', liveRecordedAnalytics);
    }
  }

  private showDisplayAttendeesCountInformaion(){
    if (this.selectedProgramsDetails!.VirtualMeetingType === 'RTMP' && !this.isRecorded ) {
      return true;
    }
    return false;
  }

  @Watch('selectedProgramsDetails')
  private onSelectedProgramsDetailsPropertyChange() {
    this.vonageAnalyticslocal = this.meetAppLiveAnalyticsdata!;
    if (this.selectedProgramsDetails!.VirtualMeetingType === VirtualMeetingEnums.VonageMUX) {
      if (this.vonageAnalyticslocal !== null && this.vonageAnalyticslocal !== undefined) {
        this.uniqueViewersDuringSession = this.vonageAnalyticslocal.Attendees;
      }
    } else if (this.selectedProgramsDetails !== null &&  this.selectedProgramsDetails !== undefined && this.selectedProgramsDetails.VodDetail !== undefined && this.selectedProgramsDetails.VodDetail !== null && this.selectedProgramsDetails.VodDetail.VodVideoAnalytics !== undefined &&  this.selectedProgramsDetails.VodDetail.VodVideoAnalytics !== null && this.selectedProgramsDetails.VodDetail.VodVideoAnalytics.CountUniqueViewersDuringSession !== undefined && this.selectedProgramsDetails.VodDetail.VodVideoAnalytics.CountUniqueViewersDuringSession !== null) {

      this.uniqueViewersDuringSession = this.selectedProgramsDetails.VodDetail.VodVideoAnalytics.CountUniqueViewersDuringSession!;
    }
    this.ShowRecordingMessage();
  }
}
export default toNative(MeetAppLiveConsolidatedDetails);
