export class VonageSessionData {
  public static createEmpty(): VonageSessionData {
    return new VonageSessionData();
  }
  constructor(
    public ProgramId: number = 0,
    public ProgramStartDateTime: Date = new Date(),
    public ProgramEndDateTime: Date = new Date(),
    public VonageUsageUrl: string = '',
    public AttendeeDatas?: AttendeeData[] | AttendeeData[],
    public Attendees: number = 0,
    public AverageSessionTime: number = 0,
    public TotalMinutesOfMeeting: number = 0,
    public ModeratorNames: string = '',
    public ModeratorsCount: number = 0,
  ) { }
}
export class AttendeeData {
  public static createEmpty(): AttendeeData {
    return new AttendeeData();
  }
  constructor(
    public AttendeeId: number = 0,
    public UserId: string = '',
    public FirstName: string = '',
    public LastName: string = '',
    public Email: string = '',
    public JoinedTimes: string = '',
    public TotalMinutes: number = 0,
    public IsModerator: boolean = false,
  ) { }
}
