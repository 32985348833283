
import { VirtualMeetingType } from '@/entities/VirtualMeetingType';
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import VirtualMeetingtypeParentEnum from '@/entities/VirtualMeetingType';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import VodTypeEnums from '@/enums/VodTypeEnums';
import VodModuleTypeEnum from '@/enums/VodModuleTypeEnum';
import { Store, Root } from '@/main';
@Component
class VirtualMeetingOptions extends Vue {
  @Prop() private selectedMeetingOption?: number;
  @Prop() private selectedItem?: ProgramItem;
  @Prop() private moduleType?: string;

  private virtualMeetingTypes: VirtualMeetingType[] = [];
  private localSelectedMeetingOption = -1;
  private isDisabled = false;
  private professionalMeetingSubHeading = '';
  private localModuleType = '';
  private storeHelper: StoreHelper = new StoreHelper(Store);
  public get virtualMeetingEnum(): typeof VirtualMeetingtypeParentEnum {
    return VirtualMeetingtypeParentEnum;
  }
  private mounted() {
    this.localSelectedMeetingOption = this.selectedMeetingOption!;
    this.localModuleType = this.moduleType!;
    // disable all tabs except meetapp live, if Meetapp live meeting has passed
    Root.on('virtual-meeting-meetapp-live-analytics-available', (val: boolean) => {
      this.isDisabled = val ;
    });
  }
  private VirtuaMeetingSelected(meetingtype: number) {
    this.localSelectedMeetingOption = meetingtype;
    // Emit selected meeting option to show appropariate panel in VirtalMeetingEditPane component
    this.$emit('selected-meeting-type', meetingtype);
    Root.emit(
      'VodMuxVideoData',
      this.selectedItem!.Id,
    );
  }
  // check if meetapp live was enabled at application level
  private isEnableMeetAppLive(): boolean {
    const appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
    if (appInfo.EnableMeetAppLive) {
      return true;
    }
    return false;
  }

  private isEnableVideoOnDemand(): boolean {
    if(this.localModuleType.toLowerCase() !==  VodModuleTypeEnum[2].toString().toLowerCase()) {
      const appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
      if (appInfo.EnableVideoOnDemand && appInfo.AllowEnableTimeZoneFeature) {
        this.professionalMeetingSubHeading = this.$t('VirtualMeetings.ExternalStreams.OptionProfessionalStreamingSubHeading1').toString() + this.$t('VirtualMeetings.ExternalStreams.OptionProfessionalStreamingSubHeading2').toString();
        return true;
      }
      this.professionalMeetingSubHeading = this.$t('VirtualMeetings.ExternalStreams.OptionProfessionalStreamingSubHeading1').toString();
      return false;
    } else if(this.localModuleType.toLowerCase() ===  VodModuleTypeEnum[2].toString().toLowerCase()) {
      const appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
      if (appInfo.EnableVideoOnDemand && appInfo.AllowEnableTimeZoneFeature && appInfo.EnableRTMPOnEventSettings) {
        this.professionalMeetingSubHeading = this.$t('VirtualMeetings.ExternalStreams.OptionProfessionalStreamingSubHeading1').toString() + this.$t('VirtualMeetings.ExternalStreams.OptionProfessionalStreamingSubHeading2').toString();
        return true;
      }
      this.professionalMeetingSubHeading = this.$t('VirtualMeetings.ExternalStreams.OptionProfessionalStreamingSubHeading1').toString();
      return false;
    } else {
      return false;
    }

  }

  private moduleTypeNotEntity() {
    return this.localModuleType.toLowerCase() !==  VodModuleTypeEnum[2].toString().toLowerCase()? true : false;
  }

  @Watch('selectedMeetingOption')
  private OnSelectedTypeChange(val: number) {
    this.localSelectedMeetingOption = val;
    this.isDisabled = false;
    if(this.selectedItem!.VonageSessionData) {
      this.isDisabled = true;
    }
    // enable tabs if these were previously disabled by Meetapp live analytics
  }
  @Watch('selectedItem')
  private programItemChanged(val: ProgramItem) {
    if (val.VodDetail !== null && val.VodDetail !== undefined) {

      if (val.VodDetail.VodStreamingType === VodTypeEnums.RTMP && (val.VodDetail.VODLatestStatus === 'VideoAssetLiveStreamCompleted' || val.VodDetail.VODLatestStatus === 'VideoAssetDeleted' )) {
        this.isDisabled = true;

      } else if (val.VirtualMeetingType === 'VonageMUX' && (val.VodDetail.VODLatestStatus === 'VideoAssetReady' || val.VodDetail.VODLatestStatus === 'VideoAssetDeleted' )) {
        // disable other option when we have VonageMUX
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    } else {
      this.isDisabled = false;
    }
  }
  @Watch('moduleType')
  private moduleTypeChanged(val: string) {
    this.localModuleType = val;
  }
}
export default toNative(VirtualMeetingOptions);
