import { SelectList } from '@/entities/SelectList';
import { VodDetail } from '@/entities/VodDetail';

export class VodBaseRenderModel {
  public static createEmpty(): VodBaseRenderModel {
    return new VodBaseRenderModel();
  }
  constructor(
    public Moduletype: string = '',
    public SubModuleType: number = 0,
    public SubModuleTypeValue: string = '',
    public ShowAddCaption: boolean = false,
    public ShowPublish: boolean = false,
    public PublishItemList: SelectList[] = [],
    public AllowMultipleVideo: boolean = false,
    public DefaultValue: number = 0,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public VodDetail: VodDetail = VodDetail,
    public EntityId: number = 0,
    public UploadUrl: string = '',
    public AcceptedVideoFileTypes: string = '',
    public AcceptedCaptionFileTypes: string = '',
    public PercentageVideoUploaded: number = 0,
    public SessionStartDate?: Date,
    public Title: string = '',
  ) {
  }
}


