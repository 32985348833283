/* eslint-disable max-len */
import { BaseSignalRModel } from '../BaseSignalRModel';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import EmitEnum from '@/enums/EmitEnums';
import { SignalRModelRequest } from '../SignalRModelRequest';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { CustomCategoryItem, CustomCategoryItemSignalR, CustomCategoryItemDetailSignalR } from '@/entities/CustomCategory/CustomCategoryItem';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';
import { Root, app } from '@/main';

const CustomCategorySignalRModelRequestIds = {
  BulkDelete: 1,
  BulkEditIconsAndImages: 2,
  SingleCreate: 3,
  SingleEdit: 4,
  SingleDelete: 5,
  SingleDetailEdit: 6,
  Sorting: 7,
  BulkAssignFolder: 8,
  BulkLinkProgram: 9,
  SharedEvents: 10,
  VideoUpload: 11,
  VideoDelete: 12,
};
export class CustomCategorySignalRModel extends BaseSignalRModel {
  // Get SignalRModelRequest for deleting seleted information Items in a batch
  get DeleteSelectedCustomCategorySignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.BulkDelete);
  }
  // Get SignalRModelRequest for updating Icons and featured images of information Items
  get EditSelectedCustomCategoryIconsAndImagesSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.BulkEditIconsAndImages);
  }
  // Get SignalRModelRequest for adding a single information item
  get SingleCreateCustomCategoryItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.SingleCreate);
  }
  // Get SignalRModelRequest for Updating a single information item
  get SingleUpdateCustomCategoryItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.SingleEdit);
  }
  // Get SignalRModelRequest for Deleting a single information item
  get SingleDeleteCustomCategoryItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.SingleDelete);
  }
  // Get SignalRModelRequest for Updating a single information item
  get VodVideoUploadCustomCategoryItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.VideoUpload);
  }
  get BulkAssignFolderSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.BulkAssignFolder);
  }
  // Get SignalRModelRequest for updating a single information item detail
  get SingleDetailEditCustomCategoryItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.SingleDetailEdit);
  }
  // Get SignalRModelRequest for updating a single information item detail
  get SortingCustomCategoryItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.Sorting);
  }
  get BulkLinkProgramSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.BulkLinkProgram);
  }
  get CustomCategorySharedEventsSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.SharedEvents);
  }
  // Get SignalRModelRequest for Deleting a single Video
  get SingleVodVideoDeleteSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(CustomCategorySignalRModelRequestIds.VideoDelete);
  }
  readonly batchOperationURL = '/BulkOperations/PerformBatchOperation';
  readonly postToClientsSignalRURL = '/BulkOperations/PostToClientsSignalR';
  constructor(public rootObj: any , public $store: any, public $t: any) {
    super(rootObj, $store);
    const reqInfo: AppRequestInfo = this.GetRequestInfoFromStore();
    const loggedInUserFullName = reqInfo.FirstName + ' ' + reqInfo.LastName;
    const signalRAPICustomCategoryURL = app.config.globalProperties.$SignalRBaseURL + 'api/SignalRInformation/PushCustomCategorySignalRChanges';

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.BulkDelete, ModuleEnum.Information, SubModuleEnum.Delete, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteCustomCategoryItems', 'Notifications.DeletedCustomCategoryItems', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingCustomCategoryDelete'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.BulkEditIconsAndImages, ModuleEnum.Information, SubModuleEnum.EditIconsAndImages,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.EditOperation', '', 'Notifications.UpdateInformationItemsImage', 'Notifications.UpdatedInformationItemsImage', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingCustomCategoryImages'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.SingleCreate, ModuleEnum.Information, SubModuleEnum.SingleCreate,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.SaveOperation', '', 'Notifications.SavingCustomCategoryItem', 'Notifications.SavedCustomCategoryItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleCustomCategory'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.SingleEdit, ModuleEnum.Information, SubModuleEnum.SingleEdit, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingCustomCategoryItem', 'Notifications.UpdatedCustomCategoryItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleCustomCategory'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.SingleDelete, ModuleEnum.Information, SubModuleEnum.SingleDelete,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteSingleCustomCategoryItem', 'Notifications.DeletedSingleCustomCategoryItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleCustomCategory'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.SingleDetailEdit, ModuleEnum.Information, SubModuleEnum.SingleDetailEdit, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingCustomCategoryItem', 'Notifications.UpdatedCustomCategoryItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleCustomCategory'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.Sorting, ModuleEnum.Information, SubModuleEnum.Sorting, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.UpdateOperation', '', 'Notifications.SortingInformationList', 'Notifications.SortedInformationList', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleCustomCategory'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.BulkAssignFolder, ModuleEnum.Information, SubModuleEnum.AssignToFolder,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.EditOperation', '', 'Notifications.UpdateInformationItemsFolder', 'Notifications.UpdatedInformationItemsFolder', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingFolders'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.BulkLinkProgram, ModuleEnum.Information, SubModuleEnum.LinkProgram,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.EditOperation', '', 'Notifications.UpdateCustomCategoryItemsLinkedProgram', 'Notifications.UpdatedCustomCategoryItemsLinkedProgram', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingLinkedProgram'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.SharedEvents, ModuleEnum.Information, SubModuleEnum.SingleDetailEdit, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.UpdateOperation', '', 'Notifications.SharingInformationWithEventsList', 'Notifications.SharedInformationWithEventsList', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleCustomCategory'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.VideoUpload, ModuleEnum.Information, SubModuleEnum.MuxVodUpdate, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingCustomCategoryItem', 'Notifications.UpdatedCustomCategoryItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionOnVideoOnDemandOperationCustomCategory'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(CustomCategorySignalRModelRequestIds.VideoDelete, ModuleEnum.Information, SubModuleEnum.MuxVodDelete, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, CustomCategorySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeletedSingleVideo', 'Notifications.DeletedSingleVideo', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionOnVideoOnDemandOperationCustomCategory'));
  }
  // Perform CustomCategory Batch Operations
  public PerformCustomCategoryBatchOperation(signalRModelRequest: SignalRModelRequest) {
    // disable CustomCategory items
    if (signalRModelRequest.SelectedIds.length > 0) {
      this.DisableCustomCategoryItems(signalRModelRequest);
    }
    this.SendSignalRModelRequestToHub(signalRModelRequest, this.batchOperationURL);

  }

  // Perform Program Single Operations
  public PerformCustomCategorySingleOperation(signalRModelRequest: SignalRModelRequest, isProgramDetails?: boolean) {
    // disable program items
    if (signalRModelRequest.SelectedIds.length > 0) {
      if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate && isProgramDetails === false) {
        this.DisableCustomCategoryItems(signalRModelRequest);
      }
    }

    this.SendSignalRModelRequestToHubWithoutAddingToQueue(signalRModelRequest, this.postToClientsSignalRURL);

    this.SendSignalRModelRequestToQueueWithoutEmit(signalRModelRequest, this.batchOperationURL);

  }
  // Method that fires when custom category SignalR response model is received from Hub
  public CustomCategorySignalRModelResponseReceived(signalRModelRequest: SignalRModelRequest) {
    this.SignalRModelResponseReceived(signalRModelRequest);
    // enable custom category items

    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate &&
            signalRModelRequest.SubModule !== SubModuleEnum.SingleDelete &&
            signalRModelRequest.SubModule !== SubModuleEnum.Sorting &&
            signalRModelRequest.SelectedIds[0] !== undefined && signalRModelRequest.SelectedIds[0] !== 0) {
      if (this.IsLoggedInUserIsMe(signalRModelRequest)) {
        this.EnableCustomCategoryItems(signalRModelRequest);
      }
    }
  }

  // Disable custom category Items and add to Notification Popup that an operation has started

  public DisableCustomCategoryItemsAndAddToNotificationPopup(signalRModelRequest: SignalRModelRequest) {
    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate &&
            signalRModelRequest.SubModule !== SubModuleEnum.SingleDetailEdit &&
            signalRModelRequest.SubModule !== SubModuleEnum.Sorting &&
            signalRModelRequest.SelectedIds[0] !== undefined && signalRModelRequest.SelectedIds[0] !== 0) {
      this.DisableCustomCategoryItems(signalRModelRequest);
    }
    this.EmitAddNewNotifications(signalRModelRequest);
  }

  // Get CustomCategory Item to send to SignalR as JSON data
  public GetCustomCategoryItemToSendToSignalR(customCategoryItem: CustomCategoryItem): string {

    const item: CustomCategoryItemSignalR = CustomCategoryItemSignalR.GetCustomCategorySignalRItem(customCategoryItem);
    return JSON.stringify(item);
  }
  // Get CustomCategory Detail Item to send to SignalR as JSON data
  public GetCustomCategoryDetailItemToSendToSignalR(customCategoryDetailItem: any): string {

    const item: CustomCategoryItemDetailSignalR = CustomCategoryItemDetailSignalR.GetCustomCategoryDetailSignalRItem(customCategoryDetailItem);
    return JSON.stringify(item);
  }
  // Update CustomCategory in array based on Index for data received from Signal R
  public FillCustomCategoryObjectFromSignalRResponse(
    customCategoryItem: CustomCategoryItemSignalR,
    objIndex: number,
    CustomCategoryList: CustomCategoryItem[],
  ): CustomCategoryItem {
    const cItem = this.GetCustomCategoryItem(CustomCategoryList[objIndex], customCategoryItem);
    return cItem;
  }

  // Create new CustomCategory Item based on Response received from SignalR
  public FillCustomCategoryObjectFromSignalRResponseNewItem(
    customCategoryItem: CustomCategoryItemSignalR,
  ): CustomCategoryItem {
    const cItem = this.GetCustomCategoryItem(CustomCategoryItem.createEmpty(), customCategoryItem);
    return cItem;
  }

  public GetSelectedCustomCategoryIdsToSendInJson(selectedItems: CustomCategoryItem[]): number[] {
    const selectedProgramIds: number[] = [];
    selectedItems.forEach((element) => {
      selectedProgramIds.push(element.CustomCategoryId);
    });
    return selectedProgramIds;
  }

  // Disable Selected CustomCategory Items based on program Ids
  private DisableCustomCategoryItems(signalRModelRequest: SignalRModelRequest) {
    Root.emit(EmitEnum.disableCustomCategoryItems, signalRModelRequest.SelectedIds);
  }

  // Enable Selected CustomCategory Items based on program Ids
  private EnableCustomCategoryItems(signalRModelRequest: SignalRModelRequest) {
    Root.emit(EmitEnum.enableCustomCategoryItems, signalRModelRequest.SelectedIds);
  }

  // Get Program Item from ProgramSignalR Item

  private GetCustomCategoryItem(pItem: CustomCategoryItem, customCategoryItem: CustomCategoryItemSignalR): CustomCategoryItem {
    pItem = CustomCategoryItem.GetCustomCategoryItemFromCustomCategoryItemSignalR(pItem, customCategoryItem);
    return pItem;
  }
}
