import { VonageSessionData } from '@/entities/VonageSessionData';

export default class LiveRecordedVideoAnalytics {
  public static createEmpty(): LiveRecordedVideoAnalytics {
    return new LiveRecordedVideoAnalytics();
  }
  constructor(
    public ShowChart: boolean = false,
    public VonageAnalytics: VonageSessionData = VonageSessionData.createEmpty(),
  ) {}
}
