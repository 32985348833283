
import { Component, toNative, Vue } from 'vue-facing-decorator';
import VirtualMeetingDetails from '@/components/Common/VirtualMeetings/VirtualMeetingDetails.vue';
import { VodBaseRenderModel } from '@/entities/FactoryPattern/Vod/VodBaseRenderModel';
import { VirtualMeetingBaseModel } from '@/entities/VirtualMeetingBaseModel';
import Datepicker from '@/components/Common/Datepicker.vue';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { StoreHelper } from '@/store/StoreHelper';
import { VonageSessionData } from '@/entities/VonageSessionData';
import VirtualMeetingCombineAnalytics from '@/components/Common/VirtualMeetings/VirtualMeetingCombineAnalytics.vue';
import MuxVideo from '@/components/Common/MuxVideo.vue';
import loading from '@/components/Common/loading.vue';
import Toaster from '@/components/Common/Toaster.vue';
// import i18n from '@/i18n';
import PreRecordedMeetingHighcharts from '@/components/Common/PreRecordedMeetingHighcharts.vue';
import ValidationSummary from '../Common/ValidationSummary.vue';
import LiveRecordedVideoAnalytics from '@/entities/Emits/WatchLive/LiveRecordedVideoAnalytics';
import { Store, Root, FetchData } from '@/main';
import VirtualMeetingEnums from '@/enums/VirtualMeetingEnums';
import VirtualMeetingtypeParentEnum from '@/enums/VirtualMeetingtypeParentEnum';
@Component({
  components: {
    VirtualMeetingDetails,
    Datepicker,
    VirtualMeetingCombineAnalytics,
    MuxVideo,
    loading,
    Toaster,
    PreRecordedMeetingHighcharts,
    ValidationSummary,
  },
})
class WatchLiveEditPane extends Vue {
  private SelectedVodBase: VodBaseRenderModel =
    VodBaseRenderModel.createEmpty();
  private SelectedItem: VirtualMeetingBaseModel =
    VirtualMeetingBaseModel.createEmpty();
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private startDateIdString = 'start';
  private endDateIdString = 'end';
  private showVirtualMeetingDetails = false;
  private showMeetAppLiveRecordingSessionAnalytics = false;
  private vonageAnalyticslocal: VonageSessionData =
    VonageSessionData.createEmpty();
  private showAddVideoSection = false;
  private showLoading = false;
  private loaderBorderColor = '';
  private showToaster = false;
  private toasterDefaultTimeout = 3000;
  private toasterMessage = '';
  private isDirty = true;
  private oldSelectedItem: VirtualMeetingBaseModel =
    VirtualMeetingBaseModel.createEmpty();
  private oldSelectedVodBase: VodBaseRenderModel =
    VodBaseRenderModel.createEmpty();
  private showPreRecordedAnalytics = false;
  private selectedMeetingOption = 0;
  private oldSelectedMeetingOption = 0;
  private firstCheck = true;
  private minDateTime = new Date().toISOString();
  private validationErrorFields: string[] = [];
  private visibleValidationSummary = false;
  public get virtualMeetingEnum(): typeof VirtualMeetingtypeParentEnum {
    return VirtualMeetingtypeParentEnum;
  }

  private mounted() {
    this.showLoading = true;
    this.SelectedVodBase.Moduletype = 'Entity';
    this.GetWatchLive();
    Root.on(
      'show-meetapp-live-recorded-video-analytics-section',
      (data: LiveRecordedVideoAnalytics) => {
        this.showMeetAppLiveRecordingSessionAnalytics = data.ShowChart;
        this.vonageAnalyticslocal = data.VonageAnalytics;
      },
    );
    Root.on('reload-watchlive-detail', () => {
      this.showMeetAppLiveRecordingSessionAnalytics =
        !this.showMeetAppLiveRecordingSessionAnalytics;
    });
    Root.on('show-add-video-section-byEntity', (addVideo: boolean) => {
      this.showAddVideoSection = addVideo ;
      this.showMeetAppLiveRecordingSessionAnalytics = addVideo ;
    });
    Root.on('show-pre-recorded-video-analytics-section', (showChart: boolean) => {
      this.showPreRecordedAnalytics = showChart ;
      this.showMeetAppLiveRecordingSessionAnalytics = showChart ;
    });
    Root.on('reload-watch-detail', (val: any) => {
      this.isDirty = true;
      if(val) {
        this.showLoading = true;
        this.toasterMessage = this.$t('EventSetting.SuccessUpdateWatchLive').toString();
        this.showToaster = true;
        setTimeout(() => {
          this.GetWatchLive();
        }, 2000);
      } else {
        alert('Some error occurs!');
      }
    });
    Root.on('reload-watchlivedelete-detail', (val: any) => {
      if(val) {
        this.toasterMessage = this.$t('EventSetting.SuccessDeleteWatchLive').toString();
        this.showToaster = true;
      } else {
        alert('Some error occurs!');
      }
    });

    Root.on('set-IsRecorded-Session', (val: boolean) => {
      this.SelectedItem.IsRecordedSession = val ;
    });
    Root.on('set-IsRecorded-Automatically', (val: boolean) => {
      this.SelectedItem.IsRecordedAutomatically = val ;
    });
    Root.on('selectedMeetingOption', (val: number) => {
      if (this.selectedMeetingOption !== val) {
        this.selectedMeetingOption = val ;
        if (!this.firstCheck) {
          this.isDirty = false;
        }
        this.firstCheck = false;
      }
    });
  }
  private CheckTimeFormatStartDate(): boolean {
    const browsersettings: BrowserSettingsInfo =
      this.storeHelper.GetBrowserSettingsFromStore();
    return browsersettings.isFullDayTimeFormat;
  }
  private CheckTimeFormatEndDate(): boolean {
    const browsersettings: BrowserSettingsInfo =
      this.storeHelper.GetBrowserSettingsFromStore();
    return browsersettings.isFullDayTimeFormat;
  }
  private DisabledDates(): boolean {
    if (this.SelectedItem.VonageSessionData) {
      return true;
    } else {
      return false;
    }
  }
  private ShowAddVideoSection() {
    return this.showAddVideoSection;
  }
  private GetWatchLive() {
    FetchData('/ApplicationInstance/GetWatchLive','')
      .then((response) => {
        this.showLoading = false;
        this.isDirty = true;
        if (response) {
          this.oldSelectedItem = Object.assign({}, response);
          this.SelectedItem = Object.assign({}, response);
          this.SelectedVodBase = Object.assign({}, response);
          this.oldSelectedVodBase = Object.assign({}, response);
          this.minDateTime = this.SelectedItem.StartDate.toISOString();
          this.SelectedVodBase.Moduletype = 'Entity';
          this.showVirtualMeetingDetails = true;
        } else {
          this.showVirtualMeetingDetails = true;
        }
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  private GetStartDate() {
    return this.SelectedItem.StartDate;
  }
  private GetEndDate() {
    return this.SelectedItem.EndDate;
  }
  private SetBrowserStartDateValue(date: Date) {
    if (date != null) {
      this.SelectedItem.StartDate = date;
      this.SelectedItem.StartDateUTC = date;
      if (
        new Date(this.SelectedItem.StartDate) >
        new Date(this.SelectedItem.EndDate)
      ) {
        this.SelectedItem.EndDate = date;
        this.SelectedItem.EndDateUTC = date;
      }
      this.minDateTime = new Date(this.SelectedItem.StartDate).toISOString();
    }
  }

  private SetBrowserEndDateValue(val: Date) {
    this.SelectedItem.EndDate = val;
    this.SelectedItem.EndDateUTC = val;
  }
  private ShowMeetAppLiveRecordingSessionAnalytics() {
    return this.showMeetAppLiveRecordingSessionAnalytics;
  }
  private SetVodDetailId(val: any) {
    // eslint-disable-next-line no-console
    console.log(val);
  }
  private SetSelectedVodBase(val: VodBaseRenderModel) {
    this.SelectedVodBase = val;
    if (this.SelectedVodBase.SubModuleType === this.virtualMeetingEnum.ProfessionalStreaming) {
      this.SelectedVodBase.SubModuleTypeValue = VirtualMeetingEnums.RTMPString;
    } else if (this.SelectedVodBase.SubModuleType === this.virtualMeetingEnum.MeetAppLive && this.SelectedVodBase.SubModuleTypeValue === VirtualMeetingEnums.VonageMUX) {
      // when we have vonageMux virtualmeeting type
      this.SelectedVodBase.SubModuleTypeValue = VirtualMeetingEnums.VonageMUX;
    } else {
      // for MUX
      this.SelectedVodBase.SubModuleTypeValue = VirtualMeetingEnums.MuxString;
    }
  }
  private vodsaveupdate() {
    // eslint-disable-next-line no-console
    console.log('called');
  }
  private SaveWatchLive() {
    // TO DO validation url
    this.validationErrorFields = [];
    this.visibleValidationSummary = false;
    if(this.SelectedItem.VirtualMeetingType === '' || this.SelectedItem.VirtualMeetingType === undefined || this.SelectedItem.VirtualMeetingType === null) {
      this.validationErrorFields.push(
        this.$t('VirtualMeetings.EventSetting.StreamMessage').toString(),
      );
      this.visibleValidationSummary = true;
      return;
    }

    if(this.SelectedItem.VirtualMeetingLink !== null && this.SelectedItem.VirtualMeetingLink.trim() !== '' && this.SelectedItem.VirtualMeetingType !== '' && this.SelectedItem.VirtualMeetingType !== 'Vonage' && this.SelectedItem.VirtualMeetingType !== 'RTMP') {
      const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[aA-zZ\u00a1-\uffff0-9]-*)*[aA-zZ\u00a1-\uffff0-9]+)(?:\.(?:[aA-zZ\u00a1-\uffff0-9]-*)*[aA-zZ\u00a1-\uffff0-9]+)*(?:\.(?:[aA-zZ\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      const regExpYoutube = /^(^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?.+)?$/;
      const regExpVimeo = /^(^(http(s)?:\/\/)?((w){3}.)?vimeo?(\.com)?.+)?$/;
      if(this.SelectedItem.VirtualMeetingType.toLowerCase() === 'youtube') {
        if (!regExpYoutube.test(this.SelectedItem.VirtualMeetingLink.trim().toLowerCase())) {
          this.validationErrorFields.push(
            this.$t('VirtualMeetings.Validation.LinkValidation').toString(),
          );
          this.visibleValidationSummary = true;
          return;
        }
      }
      if(this.SelectedItem.VirtualMeetingType.toLowerCase() === 'others') {
        if (!regexp.test(this.SelectedItem.VirtualMeetingLink.trim().toLowerCase())) {
          this.validationErrorFields.push(
            this.$t('VirtualMeetings.Validation.LinkValidation').toString(),
          );
          this.visibleValidationSummary = true;
          return;
        }
      }
      if(this.SelectedItem.VirtualMeetingType.toLowerCase() === 'vimeo') {
        if (!regExpVimeo.test(this.SelectedItem.VirtualMeetingLink.trim().toLowerCase())) {
          this.validationErrorFields.push(
            this.$t('VirtualMeetings.Validation.LinkValidation').toString(),
          );
          this.visibleValidationSummary = true;
          return;
        }
      }
    }

    this.showLoading = true;
    this.oldSelectedItem = Object.assign({},this.SelectedItem);
    this.oldSelectedVodBase = Object.assign({},this.SelectedVodBase);
    this.isDirty = true;
    FetchData('/ApplicationInstance/SaveLiveStreaming', JSON.stringify({
      virtualMeetingBaseModel: this.SelectedItem,
    }))
      .then((response) => {
        this.showLoading = false;
        if (response) {
          this.toasterMessage = this.$t('EventSetting.SuccessUpdateWatchLive').toString();
          this.showToaster = true;
        }
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  private ShowPreRecordedVideoChartSection() {
    return this.showPreRecordedAnalytics;
  }
  // anything change in model
  private IsObjectDirty() {
    if (this.showVirtualMeetingDetails) {
      const objectState = JSON.stringify(this.SelectedItem) === JSON.stringify(this.oldSelectedItem);
      if (!objectState) {
        this.isDirty = false;
      }
    }
  }
  private Discard() {
    this.SelectedItem = Object.assign({},this.oldSelectedItem);
    this.SelectedVodBase = Object.assign({},this.oldSelectedVodBase);
    this.SelectedVodBase.Moduletype = 'Entity';
    setTimeout(() => {
      this.isDirty = true;
    }, 10);
  }
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
}
export default toNative(WatchLiveEditPane);
