/* eslint-disable max-len */
export class ImageIconClass {
  public static youtubeImage: any = require('@/assets/Images/youtube.png');
  public static vimeoImage: string = require('@/assets/Images/Vimeo.png');
  public static msteamsImage: string = require('@/assets/Images/msteams.png');
  public static zoomImage: string = require('@/assets/Images/zoomapp.png');
  public static gotomeetingImage: string = require('@/assets/Images/gotomeeting.png');
  public static gotowebinarImage: string = require('@/assets/Images/gotowebinar.png');
  public static otherImage: string = require('@/assets/Images/video.png');
  public static createEmpty(): ImageIconClass {
    return new ImageIconClass();
  }

}

export class VirtualMeetingType {
  public static GetVirtualMeetingTypes(showVimeo: boolean): VirtualMeetingType[] {
    const arrVirtualMeetingTypes: VirtualMeetingType[] = [];

    arrVirtualMeetingTypes.push({ Id: 1, Key: 'MicrosoftTeams', Value: 'Microsoft Teams', Suggestions: /teams.microsoft.com/gi, ParentType: VirtualMeetingtypeParentEnum.ConferenceSoftware, ImageIcon: ImageIconClass.msteamsImage});

    arrVirtualMeetingTypes.push({ Id: 2, Key: 'Zoom', Value: 'Zoom', Suggestions: /zoom.us/gi, ParentType: VirtualMeetingtypeParentEnum.ConferenceSoftware, ImageIcon: ImageIconClass.zoomImage});

    arrVirtualMeetingTypes.push({ Id: 3, Key: 'GoToMeeting', Value: 'GoToMeeting', Suggestions: /gotomeeting.com/gi, ParentType: VirtualMeetingtypeParentEnum.ConferenceSoftware, ImageIcon: ImageIconClass.gotomeetingImage});

    arrVirtualMeetingTypes.push({ Id: 4, Key: 'GoToWebinar', Value: 'GoToWebinar', Suggestions: /gotowebinar.com/gi, ParentType: VirtualMeetingtypeParentEnum.ConferenceSoftware, ImageIcon: ImageIconClass.gotowebinarImage});

    arrVirtualMeetingTypes.push({ Id: 5, Key: 'YouTube', Value: 'YouTube', Suggestions: /youtu/gi, ParentType: VirtualMeetingtypeParentEnum.YouTubeOrVimeo, ImageIcon: ImageIconClass.youtubeImage});

    arrVirtualMeetingTypes.push({ Id: 6, Key: 'HLS', Value: 'HLS video feed', Suggestions: null, ParentType: VirtualMeetingtypeParentEnum.ProfessionalStreaming, ImageIcon: ''});
    if (showVimeo) {

      arrVirtualMeetingTypes.push({ Id: 7, Key: 'Vimeo', Value: 'Vimeo', Suggestions: /vimeo/gi, ParentType: VirtualMeetingtypeParentEnum.YouTubeOrVimeo, ImageIcon: ImageIconClass.vimeoImage});
    }

    arrVirtualMeetingTypes.push({ Id: 8, Key: 'Others', Value: 'Other invitation', Suggestions: null, ParentType: VirtualMeetingtypeParentEnum.ConferenceSoftware, ImageIcon: ImageIconClass.otherImage});

    arrVirtualMeetingTypes.push({ Id: 6, Key: 'Vonage', Value: 'Meetapp Live', Suggestions: null, ParentType: VirtualMeetingtypeParentEnum.MeetAppLive, ImageIcon: ''});

    arrVirtualMeetingTypes.push({ Id: 6, Key: 'Mux', Value: 'Pre recorded', Suggestions: null, ParentType: VirtualMeetingtypeParentEnum.PreRecordedVideo, ImageIcon: ''});

    arrVirtualMeetingTypes.push({ Id: 9, Key: 'RTMP', Value: 'RTMP', Suggestions: null, ParentType: VirtualMeetingtypeParentEnum.ProfessionalStreaming, ImageIcon: ''});

    arrVirtualMeetingTypes.push({ Id: 10, Key: 'VonageMUX', Value: 'Meetapp Live Recording', Suggestions: null, ParentType: VirtualMeetingtypeParentEnum.MeetAppLive, ImageIcon: ''});
    return arrVirtualMeetingTypes;
  }
  constructor(
    public Id: number = 0,
    public Key: string = '',
    public Value: string = '',
    public Suggestions: any,
    public ParentType: number = 0,
    public ImageIcon: string = '',
  ) {}
}
const VirtualMeetingtypeParentEnum = {
  Others: 0,
  YouTubeOrVimeo: 1,
  ConferenceSoftware: 2,
  MeetAppLive: 3,
  ProfessionalStreaming: 4,
  PreRecordedVideo: 5,
};

export default Object.freeze(VirtualMeetingtypeParentEnum);

