
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { VirtualMeetingType } from '@/entities/VirtualMeetingType';
import VirtualMeetingOptions from '@/components/Common/VirtualMeetings/VirtualMeetingOptions.vue';
import VirtualMeetingEditPane from '@/components/Common/VirtualMeetings/VirtualMeetingEditPane.vue';
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import VirtualMeetingEnums from '@/enums/VirtualMeetingEnums';
import VodTypeEnums from '@/enums/VodTypeEnums';
import { VodBaseRenderModel } from '@/entities/FactoryPattern/Vod/VodBaseRenderModel';
import { VodDetail } from '@/entities/VodDetail';
import VirtualMeetingtypeParentEnum from '@/enums/VirtualMeetingtypeParentEnum';
import Common from '@/helper/Common';
import VodModuleTypeEnum from '@/enums/VodModuleTypeEnum';
import { Root, Store } from '@/main';
@Component({
  components: {
    VirtualMeetingOptions,
    VirtualMeetingEditPane,
  },
})
class VirtualMeetingDetails extends Vue {
  @Prop() private selectedItem?: ProgramItem;
  @Prop() private selectedVodBase?: VodBaseRenderModel;

  private virtualMeetingStreamerType = '';
  private selectedMeetingOption = 0;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private virtualMeetingTypes: VirtualMeetingType[] = [];
  private vonageThresholdLimitReached = false;
  private localSelectedVodBase: VodBaseRenderModel = VodBaseRenderModel.createEmpty();
  private created() {
    this.virtualMeetingTypes = VirtualMeetingType.GetVirtualMeetingTypes(this.isEnableVimeoVideos());
  }

  private GetThresholdLimitReachedMsg() {
    if (this.selectedMeetingOption === 3) {
      return this.$t('VirtualMeetings.MeetAppLive.TresholdLimitReached');
    } else {
      return this.$t('VirtualMeetings.PreRecordedDetails.TresholdLimitReached');
    }
  }
  private IsEventModule(): boolean {
    if(this.localSelectedVodBase.Moduletype.toLowerCase() !== VodModuleTypeEnum[2].toString().toLowerCase()) {
      return true;
    } else {
      return false;
    }
  }
  private mounted() {
    if(this.selectedVodBase !== null && this.selectedVodBase !==undefined) {
      this.localSelectedVodBase = Object.assign({},this.selectedVodBase);
    }
    this.virtualMeetingStreamerType = this.selectedItem!.VirtualMeetingType;
    if (this.virtualMeetingStreamerType !== '') {

      if ((this.virtualMeetingStreamerType === 'Mux' && (this.selectedItem!.VodDetail === null || this.selectedItem!.VodDetail === undefined) ) || (this.virtualMeetingStreamerType === 'Mux' && this.selectedItem!.VodDetail!.Id === 0 )) {
        this.SelectedMeetingTypeChange(this.selectedMeetingOption);
      }

    } else if (this.selectedItem!.VodDetail! && this.selectedItem!.VodDetail.Id > 0 && this.selectedItem!.VodDetail.VodStreamingType === 1) {
      this.virtualMeetingStreamerType = 'Mux';

    } else if (this.selectedItem!.VodDetail! && this.selectedItem!.VodDetail.Id > 0 && this.selectedItem!.VodDetail.VodStreamingType === 2) {
      this.virtualMeetingStreamerType = 'RTMP';

    } else if (this.selectedItem!.VodDetail! && this.selectedItem!.VodDetail.Id > 0 && this.selectedItem!.VodDetail.VodStreamingType === 3) {
      // set vonageMUX
      this.virtualMeetingStreamerType = 'VonageMUX';
    }
    const meetingTypes = this.virtualMeetingTypes.find(
      (element) => element.Key === this.virtualMeetingStreamerType,
    );
    if(meetingTypes !== null && meetingTypes !== undefined) {
      this.selectedMeetingOption = meetingTypes.ParentType;
    }
    Root.on(
      'set-selected-meeting-type',
      (type: string) => {
        this.virtualMeetingStreamerType = type;
        if (this.virtualMeetingStreamerType !== '') {

          this.selectedMeetingOption = this.virtualMeetingTypes.find((element) => element.Key === this.virtualMeetingStreamerType)!.ParentType;
        }
      },
    );
    Root.on('reload-VodDetail-delete-program', () => {
      this.localSelectedVodBase.VodDetail = VodDetail.createEmpty();
      this.selectedItem!.VodDetail = VodDetail.createEmpty();
    });
    if(this.selectedMeetingOption !== 0) {
      this.SelectedMeetingTypeChange(this.selectedMeetingOption);
    }
  }
  private async VerifyThresholdLimit() {
    this.vonageThresholdLimitReached = await Common.prototype.VerifyThresholdLimit();
  }

  // showThreshHold
  private showThreshHold(val: any) {
    if(this.selectedItem!.VodDetail === null || this.selectedItem!.VodDetail === undefined  ) {
      this.vonageThresholdLimitReached = val;
    } else {
      if(this.selectedItem!.VodDetail.Id === 0) {
        this.vonageThresholdLimitReached = val;
      } else {
        this.vonageThresholdLimitReached = false;
      }
    }
  }
  private SelectedMeetingTypeChange(meetingType: number) {
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    if (meetingType === VirtualMeetingtypeParentEnum.MeetAppLive
       || meetingType === VirtualMeetingtypeParentEnum.PreRecordedVideo
        || meetingType === VirtualMeetingtypeParentEnum.ProfessionalStreaming) {
      if(this.selectedMeetingOption !== meetingType) {
        if(reqInfo.VerifyThresholdLimit){
          if(!reqInfo.IsThresholdLimitReached) {
            this.VerifyThresholdLimit();
          } else {
            this.vonageThresholdLimitReached = true;
          }
        }
      } else {
        if(!reqInfo.IsThresholdLimitReached) {
          this.vonageThresholdLimitReached = false;
        } else {
          // dev 5277
          if (this.selectedItem!.VodDetail === null || this.selectedItem!.VodDetail === undefined) {
            this.vonageThresholdLimitReached = true;
          }
        }
      }
    } else {
      this.vonageThresholdLimitReached = false;
    }
    // Meet App Live & VOD
    if (meetingType === VirtualMeetingtypeParentEnum.MeetAppLive
       || meetingType === VirtualMeetingtypeParentEnum.PreRecordedVideo) {
      this.selectedMeetingOption = meetingType;
    } else {
      this.selectedMeetingOption = meetingType;
    }
    if(this.localSelectedVodBase !== null && this.localSelectedVodBase !== undefined && this.selectedItem !== null && this.selectedItem !== undefined) {
      this.localSelectedVodBase.SubModuleType = this.selectedMeetingOption;
      this.localSelectedVodBase.EntityId = this.selectedItem.Id;
    }
    this.$emit('selected-meeting-type',this.localSelectedVodBase);
  }
  private isEnableVimeoVideos(): boolean {
    const appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
    if (appInfo.EnableVimeoVideos) {
      return true;
    }
    return false;
  }
  @Watch('selectedItem')
  private onProgramItemChange(val: ProgramItem) {
    this.vonageThresholdLimitReached = false;
    this.virtualMeetingStreamerType = val.VirtualMeetingType;
    this.localSelectedVodBase.SubModuleTypeValue =  this.virtualMeetingStreamerType;
    this.localSelectedVodBase.EntityId = val.Id;

    if (this.virtualMeetingStreamerType === '') {
      if (val.Id === 0) {
        this.virtualMeetingStreamerType = '';
      } else if (val.VodDetail && val.VodDetail.Id > 0) {
        if (val.VodDetail.VodStreamingType === VodTypeEnums.Mux) {
          this.virtualMeetingStreamerType = VirtualMeetingEnums.MuxString;
        } else {
          this.virtualMeetingStreamerType = VirtualMeetingEnums.RTMPString;
        }
      }
    }
    if (this.virtualMeetingStreamerType !== '') {
      const meetingTypes = this.virtualMeetingTypes.find(
        (element) => element.Key === this.virtualMeetingStreamerType,
      );
      if(meetingTypes !== null && meetingTypes !== undefined) {
        this.selectedMeetingOption = meetingTypes.ParentType;
      }
    } else {
      this.selectedMeetingOption = -1;
    }

    if ((this.virtualMeetingStreamerType === 'Mux' && (this.selectedItem!.VodDetail === null || this.selectedItem!.VodDetail === undefined) ) || (this.virtualMeetingStreamerType === 'Mux' && this.selectedItem!.VodDetail!.Id === 0 )) {
      this.SelectedMeetingTypeChange(this.selectedMeetingOption);
    }
    this.localSelectedVodBase.SubModuleType = this.selectedMeetingOption;
    this.$emit('selected-meeting-type',this.localSelectedVodBase);
  }


  @Watch('selectedMeetingOption')
  private onselectedMeetingOptionChange(val: any) {
    Root.emit('selectedMeetingOption', val);
  }
  @Watch('selectedVodBase')
  private ChangeInSelectedVodBase(val: any) {
    this.localSelectedVodBase = Object.assign({},val);
  }
}
export default toNative(VirtualMeetingDetails);
